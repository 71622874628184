<template>
  <div class="card">
    <div id="toolbar" class="fixed-table-toolbar">
      <div class="float-left">
        <h2 style="padding-left: 10px; padding-top: 10px; padding-bottom: 0px;"> <span class="icon-list"></span>   Count's List</h2>
      </div>
      <div class="float-right search">
        <input  id="txtsearch" class="form-control" type="text" placeholder="Search" autocomplete="off">
      </div>
    </div>

    <div class="table-responsive">
      <table id="count-table"
             class="table table-borderless table-condensed"
             data-search="false"
             data-pagination="true"
             data-show-refresh="false"
             data-show-columns="false"
             data-page-list="[10, 25, 50, 100, ALL]"
             data-show-footer="false"
             data-toggle="context"
             data-target=".context-table">
        <thead>
        <tr>
          <th data-field="id" data-class="d-none">id</th>
          <th data-field="code" data-width="100">Code</th>
          <th data-field="name" >Count</th>
          <th data-field="hsn" >HSN</th>
          <th data-field="gst_rate" data-formatter="indianFormat" data-align="right">GST %</th>
          <th data-field="itc_rate" data-formatter="indianFormat" data-align="right">ITC %</th>
          <th data-field="weight" data-align="right" data-formatter="weightFormat">Weight</th>
        </tr>
        </thead>
        <tbody>
        </tbody>
        <tfoot>
        </tfoot>
      </table>
    </div>

    <!-- Context Menu -->
    <div id="context-menu" class="context-table">
      <div class="dropdown-menu">
        <a href="#" class="dropdown-item" @click="loadData"><i class="icon-reset" style="color: darkgreen"></i>Refresh</a>
        <!--<div class="dropdown-divider"></div>-->
        <!--<a href="#" class="dropdown-item"><i class="icon-cross2 text-danger"></i>Discard</a>-->
        <div class="dropdown-divider"></div>
        <a href="#" class="dropdown-item"> <i class="icon-pencil3" ></i>Modify</a>
        <div class="dropdown-divider"></div>
        <a href="#" class="dropdown-item"><i class="icon-blocked text-danger"></i>Remove</a>
      </div>
    </div>
    <!-- End of Context Menu -->

    <!-- Clickable menu -->
    <ul class="fab-menu fab-menu-fixed fab-menu-bottom-right is_stuck" data-fab-toggle="hover" z-index="99">
      <li>
        <a class="fab-menu-btn btn bg-primary-600 btn-float rounded-round btn-icon" @click="showModal">
          <i class="fab-icon-open icon-plus3"></i>
          <i class="fab-icon-close icon-plus3"></i>
        </a>
      </li>
    </ul>
    <!-- /clickable menu -->

    <vmodal name="count-window" transition="nice-modal-fade" :delay="100" :resizable="true" width="60%" height="58%" >
      <CountForm v-bind:mycount="count"></CountForm>
    </vmodal>


  </div>

</template>

<script>
  import CountForm from '@/components/v1/tms/count/CountForm.vue'
  import FabButton from '@/components/core/FabButton.vue'
  import moment from 'moment-timezone'
  import {userService} from '@/store/auth-header.js'
  export default {
    name: 'CountView',
    components: {
      CountForm,
      FabButton
    },
    data () {
      return {
        mytable: {},
        count: JSON.parse('{"id":"","code":0,"type":0,"status":0,"name":"","p_name":"","hsn":"","gst_rate":0,"itc_rate":0,"main_unit":{"id":"","code":0,"name":"","digits":0,"sys_obj":false},"conv_fact":0,"sub_unit":{"id":"","code":0,"name":"","digits":0,"sys_obj":false},"weight":0}')
      }
    },
    created () {

    },
    mounted () {
      let self = this;

      this.$data.mytable = $('#count-table');

      this.$data.mytable.bootstrapTable();
      $('[data-toggle="popover"]').popover();

      this.$data.mytable.contextmenu({
        target: '#context-menu',
        scopes: 'tbody > tr',
        onItem: function (row, e) {
          var id = $(row.children('*')[0]).text();

          if ($(e.target).text() === 'Modify') {
            self.modifyDocument (id);
          } else if ($(e.target).text() === 'Remove') {
            self.removeDocument(id);
          }
        }
      });

      $('#txtsearch').keyup(function () {
        var val = $.trim($(this).val()).replace(/ +/g, ' ').toLowerCase()
        $('#fcc-table>tbody>tr').show().filter(function () {
          var text = $(this).text().replace(/\s+/g, ' ').toLowerCase()
          return !~text.indexOf(val)
        }).hide()
      });

      self.loadData();
    },
    methods: {
      closeModal () {
        this.$modal.hide('count-window');
      },
      showModal () {
        this.$modal.show('count-window');
      },
      beforeOpen(){
        console.log('beforeOpen');
      },
      beforeClose(){
        // this.loadData();
      },
      loadData () {
        let self = this;

        const requestOptions = {
          method: 'GET',
          mode:"cors",
          headers: userService.authHeader()
        };

        self.$data.mytable.bootstrapTable('load',[]);

        fetch(`${process.env.VUE_APP_ROOT_API}v1/tms/count/`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if(resp.accepted) {
            if(resp.data != null){
              self.$data.mytable.bootstrapTable('load', resp.data);
            }
          } else {
            swal ( { title: "Oh noes!" ,  text: resp.message, icon:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: err.toString(), icon:  "error"} );
        });

      },
      modifyDocument (id) {
        let self = this;

        const requestOptions = {
          method: 'GET',
          mode:"cors",
          headers: userService.authHeader()
        };

        self.$data.count = JSON.parse('{"id":"","code":0,"type":0,"status":0,"name":"","p_name":"","hsn":"","gst_rate":0,"itc_rate":0,"main_unit":{"id":"","code":0,"name":"","digits":0,"sys_obj":false},"conv_fact":0,"sub_unit":{"id":"","code":0,"name":"","digits":0,"sys_obj":false},"weight":0}');

        fetch(`${process.env.VUE_APP_ROOT_API}v1/tms/count/${id}/`,requestOptions).then(userService.handleResponse).then(function (data) {
          if(data.accepted) {
            self.$data.count = data.data;
            self.$modal.show('count-window');
          } else {
            swal ( { title: "Oh noes!" ,  text: data.message, icon:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text:  err.toString() , icon:  "error"} );
        });
      },
      removeDocument (id) {
        let self = this;

        self.$data.count.id = id;

        const requestOptions = {
          method: 'DELETE',
          mode:'cors',
          headers: userService.authHeader(),
          body: JSON.stringify(self.$data.count)
        };

        swal({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          type: 'warning',
          timer: 3000,
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete tms!'
        }).then((result) => {
          if (result.value) {
            fetch(`${process.env.VUE_APP_ROOT_API}v1/tms/count/${id}`,requestOptions).then(userService.handleResponse).then(function (resp) {
              self.$modal.hide('count_window')
              self.loadData();
              swal(
                'Deleted!',
                'Your request has been processed',
                'success'
              );
            }).catch(function (err) {
              swal({ title: "Oops", text: err.toString(), type: "error" });
            });
          }
        });
      },
      saveDocument () {

        let self = this;

        if(self.$data.count.name.trim().length < 1){
          alert("Invalid Name");
          return
        }

        const requestOptions = {
          method:  (self.$data.count.code == 0 ? 'POST' : 'PUT'),
          mode:"cors",
          headers: userService.authHeader(),
          body: JSON.stringify(self.$data.count)
        };

        fetch(`${process.env.VUE_APP_ROOT_API}v1/ams/count/`,requestOptions).then(userService.handleResponse).then(function (data) {
          if(data.accepted) {
            swal ( { title: "Success!" ,  text: data.message, icon:  "success"} );
            self.count = JSON.parse('{"id":"","code":0,"type":0,"status":0,"name":"","p_name":"","hsn":"","gst_rate":0,"itc_rate":0,"main_unit":{"id":"","code":0,"name":"","digits":0,"sys_obj":false},"conv_fact":0,"sub_unit":{"id":"","code":0,"name":"","digits":0,"sys_obj":false},"weight":0}');
            self.closeModal();
          } else {
            swal ( { title: "Oh noes!" ,  text: data.message, icon:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: err.toString(), icon:  "error"} );
        });
      }
    }
  }
</script>

<style scoped>
  .modal {
    text-align: center;
    padding: 0!important;
  }

  .modal:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }

  .modal-dialog {
    display: inline-block;
    text-align: left;
    width: 40%;
    vertical-align: middle;
  }
</style>
