<template>

  <div class="card">

    <div class="card-header header-elements-inline" >
      <h5 class="card-title">Count Creation</h5>
      <div class="header-elements">
        <div class="list-icons">
          <a class="list-icons-item" data-action="remove" @click="closeWindow"></a>
        </div>
      </div>
    </div>

    <div class="card-body">
      <form action="#">

        <div class="form-group row">
          <label class="col-md-2 col-form-label">Count:</label>
          <div class="col-md-10">
            <input  id="txtname" type="text" class="form-control" placeholder="Enter Count Name here..." maxlength="100" autofocus v-if="count" v-model="count.name" >
          </div>
        </div>

        <div class="form-group row">
          <label class="col-md-2 col-form-label">Print Name:</label>
          <div class="col-md-10">
            <input type="text" class="form-control" placeholder="Enter your print name here..." maxlength="100" v-if="count" v-model="count.p_name">
          </div>
        </div>



        <div class="form-group row">
          <label class="col-md-2 col-form-label">HSN:</label>
          <div class="col-md-4">
            <input type="text" class="form-control" placeholder="Enter HSN here..." maxlength="8" v-if="count" v-model="count.hsn">
          </div>

          <label class="col-md-2 col-form-label">GST %:</label>
          <div class="col-md-1">
            <input type="number" class="form-control text-right" placeholder="Enter GST Rate..." min="0" max="42" v-if="count" v-model="count.gst_rate">
          </div>
          <label class="col-md-2 col-form-label">ITC %:</label>
          <div class="col-md-1">
            <input type="number" class="form-control text-right" placeholder="Enter ITC here..." min="0" max="100" v-if="count" v-model="count.itc_rate">
          </div>
        </div>

        <div class="form-group row">

          <label class="col-md-2 col-form-label">Weight</label>
          <div class="col-md-2">
            <input type="number" class="form-control text-right" step="any" placeholder="Weight/Unit" min="0" v-if="count" v-model="count.weight">
          </div>

          <label class="col-md-1 col-form-label">Unit</label>
          <div class="col-md-1">
            <select class="form-control" v-if="count" v-model="count.main_unit.code">
              <option value="1">Nos</option>
              <option value="2">Kgs</option>
              <option value="3">Mts</option>
              <option value="5">Doz</option>
              <option value="1001">Bundle</option>
              <option value="1002">Knots</option>
            </select>
          </div>

          <label class="col-md-2 col-form-label">Conv Fact:</label>
          <div class="col-md-1">
            <input type="number" class="form-control text-right" placeholder="Conv Fact" step="1" min="1" max="300" v-if="count" v-model="count.conv_fact">
          </div>

          <label class="col-md-2 col-form-label">Sub Unit:</label>
          <div class="col-md-1">
            <select class="form-control" v-if="count" v-model="count.sub_unit.code">
              <option value="1">Nos</option>
              <option value="2">Kgs</option>
              <option value="3">Mts</option>
              <option value="5">Doz</option>
              <option value="1001">Bundle</option>
              <option value="1002">Knots</option>
            </select>
          </div>

        </div>

      </form>
    </div>

    <div class="card-footer">
      <div class="text-right" >
        <button type="button" class="btn btn-primary" @click="saveDocument" >Save<i class="icon-paperplane ml-2"></i></button>
      </div>
    </div>
  </div>
</template>

<script>
  import {userService} from '@/store/auth-header.js'
  export default {
    name: 'CountForm',
    data () {
      return {
        count: JSON.parse('{"id":"","code":0,"type":0,"status":0,"name":"","p_name":"","hsn":"","gst_rate":0,"itc_rate":0,"main_unit":{"id":"","code":0,"name":"","digits":0,"sys_obj":false},"conv_fact":0,"sub_unit":{"id":"","code":0,"name":"","digits":0,"sys_obj":false},"weight":0}')
      }
    },
    props: {
      mycount: {
        type: Object,
        default: () => JSON.parse('{"id":"","code":0,"type":0,"status":0,"name":"","p_name":"","hsn":"","gst_rate":0,"itc_rate":0,"main_unit":{"id":"","code":0,"name":"","digits":0,"sys_obj":false},"conv_fact":0,"sub_unit":{"id":"","code":0,"name":"","digits":0,"sys_obj":false},"weight":0}')
      }
    },
    beforeMount () {
      this.count = this.mycount; // save props data to itself's data and deal with tms
    },
    component: {

    },
    created () {
      this.$data.count = JSON.parse('{"id":"","code":0,"type":0,"status":0,"name":"","p_name":"","hsn":"","gst_rate":0,"itc_rate":0,"main_unit":{"id":"","code":0,"name":"","digits":0,"sys_obj":false},"conv_fact":0,"sub_unit":{"id":"","code":0,"name":"","digits":0,"sys_obj":false},"weight":0}')
    },
    mounted () {
      $('#txtname').focus();
      // $('.form-control-select2').select2()
    },
    methods: {
      closeWindow(){
        this.$parent.$parent.closeModal();
      },
      clear(){
        this.$data.count = JSON.parse('{"id":"","code":0,"type":0,"status":0,"name":"","p_name":"","hsn":"","gst_rate":0,"itc_rate":0,"main_unit":{"id":"","code":0,"name":"","digits":0,"sys_obj":false},"conv_fact":0,"sub_unit":{"id":"","code":0,"name":"","digits":0,"sys_obj":false},"weight":0}')
      },
      saveDocument(){
        let self = this;

        self.$data.count.weight = parseFloat(self.$data.count.weight);
        self.$data.count.gst_rate = parseFloat(self.$data.count.gst_rate);
        self.$data.count.itc_rate = parseFloat(self.$data.count.itc_rate);

        self.$data.count.main_unit.code = parseInt(self.$data.count.main_unit.code);
        self.$data.count.conv_fact = parseInt(self.$data.count.conv_fact);
        self.$data.count.sub_unit.code = parseInt(self.$data.count.sub_unit.code);


        if(self.$data.count.name.trim().length < 1){
          alert("Invalid Name");
          return
        }else if(self.$data.count.main_unit.code < 1){
          alert("Invalid Unit Code");
          return
        }else if(self.$data.count.sub_unit.code < 1){
          alert("Invalid Sub Unit Code");
          return
        }else if(self.$data.count.conv_fact < 1){
          alert("Invalid Conversion Factor");
          return
        }

        const requestOptions = {
          method:  (self.$data.count.code == 0 ? 'POST' : 'PUT'),
          mode:"cors",
          headers: userService.authHeader(),
          body: JSON.stringify(self.$data.count)
        };

        fetch(`${process.env.VUE_APP_ROOT_API}v1/tms/count/`,requestOptions).then(userService.handleResponse).then(function (data) {
          if(data.accepted) {
            swal ( { title: "Success!" ,  text: data.message, icon:  "success"} );
            self.group = JSON.parse('{"id":"","code":0,"type":0,"status":0,"name":"","p_name":"","hsn":"","gst_rate":0,"itc_rate":0,"main_unit":{"id":"","code":0,"name":"","digits":0,"sys_obj":false},"conv_fact":0,"sub_unit":{"id":"","code":0,"name":"","digits":0,"sys_obj":false},"weight":0}');
            self.$parent.$parent.loadData();
            self.closeWindow();
          } else {
            swal ( { title: "Oh noes!" ,  text: data.message, icon:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: err.toString(), icon:  "error"} );
        });
      }
    }
  }
</script>

<style scoped>

</style>
